import { useState, useEffect } from "react"

import { initialPositions, receivingPositions, boundaries } from "./Positions"

function Test () {

    const [rotation, setRotation] = useState(1)

    const [selectedPlayer, setSelectedPlayer] = useState()
    const [lockSelectedPlayer, setLockSelectedPlayer] = useState(false)

    const [position, setPosition] = useState("initial")

    const positions = {
        initial: initialPositions,
        receiving: receivingPositions
    }

    const [playerNames, setPlayerNames] = useState(window.innerWidth < 768 || window.innerHeight < 768 ?
        {
            setter: "A",
            outsideHitter1: "P1",
            middleBlocker1: "C1",
            rightSideHitter: "O",
            outsideHitter2: "P2",
            libero: "L",
            middleBlocker2: "C2"
        } :
        {
            setter: "Armador",
            outsideHitter1: "Punta 1",
            middleBlocker1: "Central 1",
            rightSideHitter: "Opuesto",
            outsideHitter2: "Punta 2",
            libero: "Líbero",
            middleBlocker2: "Central 2"
        }
    )
    

    const onPlayerClick = (player) => {
        if (lockSelectedPlayer) {
            if (selectedPlayer === player) {
                setSelectedPlayer()
                setLockSelectedPlayer(false)
            } else {
                setSelectedPlayer(player)
            }
        } else {
            setSelectedPlayer(player)
            setLockSelectedPlayer(true)
        }
    }

    const onPlayerHover = (player) => {
        if (!lockSelectedPlayer) {
            setSelectedPlayer(player)
        }
    }

    const onPlayerHoverExit = (player) => {
        if (!lockSelectedPlayer) {
            setSelectedPlayer()
        }
    }

    useEffect(() => {
        document.querySelector(`.selected`)?.classList.remove("selected")
        document.querySelectorAll(`.pulse`)?.forEach(pulse => {
            pulse.classList.remove("pulse")
            void pulse.offsetWidth //To trigger a DOM Reflow hence resetting the animations and keeping all in sync
        })
        if (selectedPlayer) {
            document.querySelector(`#${selectedPlayer}`).classList.add("selected")
            boundaries[selectedPlayer][rotation-1].forEach(player => {
                document.querySelector(`#${player.player}`).classList.add("pulse")
            })
        }
    },[selectedPlayer, rotation])

    const player = (playerID, playerClass) => {
        return (
            <button
                id={playerID}
                className={`player ${playerClass}`}
                style={positions[position][playerID][rotation-1]}
                onMouseDown={() => onPlayerClick(playerID)}
                onMouseEnter={() => onPlayerHover(playerID)}
                onMouseLeave={() => onPlayerHoverExit(playerID)}
            >
                <div>
                    <input
                        className="player-label"
                        type="text"
                        value={playerNames[playerID]}
                        onMouseDown={e=>{e.stopPropagation()}}
                        onChange={(e)=>
                            setPlayerNames(oldNames => {
                                return {...oldNames, [playerID]: e.target.value}
                            })
                        }
                    />
                    {lockSelectedPlayer && selectedPlayer === playerID && <img src={"/icons8-lock.svg"} alt="lockIcon"></img>}
                </div>
            </button>
        )
    }

    return (
        <div className="stadium">
            <div className="court">
                <div className="three-line"></div>

                <span className="player-position" style={{top:"3%",left:"13%"}}>4</span>
                <span className="player-position" style={{top:"3%",left:"43.5%"}}>3</span>
                <span className="player-position" style={{top:"3%",left:"73%"}}>2</span>
                <span className="player-position" style={{top:"53%",left:"13%"}}>5</span>
                <span className="player-position" style={{top:"53%",left:"43.5%"}}>6</span>
                <span className="player-position" style={{top:"53%",left:"73%"}}>1</span>

                <div className="boundaries">
                    {selectedPlayer && boundaries[selectedPlayer][rotation-1].map((player, index) => {
                        return (
                            <div
                                key={index}
                                className={
                                    player.border === "left" || player.border === "right"
                                    ? "boundary-vertical"
                                    : "boundary-horizontal"
                                }
                                style={
                                    player.border === "left" || player.border === "right"
                                    ? {width:positions[position][player.player][rotation-1][player.border], [player.border]:0} 
                                    : {height:positions[position][player.player][rotation-1][player.border], [player.border]:0}
                                }>
                            </div>
                        )
                    })}
                </div>
                {player("setter", "setter")}
                {player("outsideHitter1", "outside-hitter")}
                {player("middleBlocker1", "middle-blocker")}
                {player("rightSideHitter", "right-side-hitter")}
                {player("outsideHitter2", "outside-hitter")}
                {player("libero", "libero")}
                {player("middleBlocker2", "middle-blocker")}
            </div>
            <div className="button-panel">
                <div className="button-group">
                    <button className="button-basic button-rounded" onClick={()=>setRotation(oldRotation => {
                        if (oldRotation <= 1) {
                            return 6
                        }
                        return oldRotation - 1
                    })}>{"<"}</button>
                    <span style={{marginLeft:"0.5rem", marginRight:"0.5rem", fontWeight:"500"}}>{rotation}</span>
                    <button className="button-basic button-rounded" onClick={()=>setRotation(oldRotation => {
                        if (oldRotation >= 6) {
                            return 1
                        }
                        return oldRotation + 1
                    })}>{">"}</button>
                </div>
                <div className="button-group" style={{textAlign:"center"}}>
                    <button className="button-basic button-curved" style={{minWidth: "6rem"}} onClick={()=>setPosition(oldPosition => {
                        if (oldPosition === "initial") {
                            return "receiving"
                        }
                        return "initial"
                    })}>{position === "initial" ? "Inicial" : "Recepción"}</button>
                </div>
                <div className="button-group">
                    
                </div>
            </div>
        </div>
    )
}

export default Test