export const initialPositions = {
    setter: [
        {top:"12%", right:"15%", bottom:"78%", left:"75%"},
        {top:"62%", right:"15%", bottom:"28%", left:"75%"},
        {top:"62%", right:"45%", bottom:"28%", left:"45%"},
        {top:"62%", right:"75%", bottom:"28%", left:"15%"},
        {top:"12%", right:"75%", bottom:"78%", left:"15%"},
        {top:"12%", right:"45%", bottom:"78%", left:"45%"},
    ],
    outsideHitter1: [
        {top:"12%", right:"45%", bottom:"78%", left:"45%"},
        {top:"12%", right:"15%", bottom:"78%", left:"75%"},
        {top:"62%", right:"15%", bottom:"28%", left:"75%"},
        {top:"62%", right:"45%", bottom:"28%", left:"45%"},
        {top:"62%", right:"75%", bottom:"28%", left:"15%"},
        {top:"12%", right:"75%", bottom:"78%", left:"15%"},
    ],
    middleBlocker1: [
        {top:"12%", right:"75%", bottom:"78%", left:"15%"},
        {top:"12%", right:"45%", bottom:"78%", left:"45%"},
        {top:"12%", right:"15%", bottom:"78%", left:"75%"},
        {top:"12%", right:"104%", bottom:"78%", left:"-14%"},
        {top:"12%", right:"104%", bottom:"78%", left:"-14%"},
        {top:"12%", right:"104%", bottom:"78%", left:"-14%"},
    ],
    rightSideHitter: [
        {top:"62%", right:"75%", bottom:"28%", left:"15%"},
        {top:"12%", right:"75%", bottom:"78%", left:"15%"},
        {top:"12%", right:"45%", bottom:"78%", left:"45%"},
        {top:"12%", right:"15%", bottom:"78%", left:"75%"},
        {top:"62%", right:"15%", bottom:"28%", left:"75%"},
        {top:"62%", right:"45%", bottom:"28%", left:"45%"},
    ],
    outsideHitter2: [
        {top:"62%", right:"45%", bottom:"28%", left:"45%"},
        {top:"62%", right:"75%", bottom:"28%", left:"15%"},
        {top:"12%", right:"75%", bottom:"78%", left:"15%"},
        {top:"12%", right:"45%", bottom:"78%", left:"45%"},
        {top:"12%", right:"15%", bottom:"78%", left:"75%"},
        {top:"62%", right:"15%", bottom:"28%", left:"75%"},
    ],
    libero: [
        {top:"62%", right:"15%", bottom:"28%", left:"75%"},
        {top:"62%", right:"45%", bottom:"28%", left:"45%"},
        {top:"62%", right:"75%", bottom:"28%", left:"15%"},
        {top:"62%", right:"15%", bottom:"28%", left:"75%"},
        {top:"62%", right:"45%", bottom:"28%", left:"45%"},
        {top:"62%", right:"75%", bottom:"28%", left:"15%"},
    ],
    middleBlocker2: [
        {top:"12%", right:"104%", bottom:"78%", left:"-14%"},
        {top:"12%", right:"104%", bottom:"78%", left:"-14%"},
        {top:"12%", right:"104%", bottom:"78%", left:"-14%"},
        {top:"12%", right:"75%", bottom:"78%", left:"15%"},
        {top:"12%", right:"45%", bottom:"78%", left:"45%"},
        {top:"12%", right:"15%", bottom:"78%", left:"75%"},
    ]
}

export const receivingPositions = {
    setter: [
        {top:"5%", right:"25%", bottom:"85%", left:"65%"},
        {top:"68%", right:"3%", bottom:"22%", left:"87%"},
        {top:"5%", right:"25%", bottom:"85%", left:"65%"},
        {top:"28.5%", right:"55%", bottom:"61.5%", left:"35%"},
        {top:"5%", right:"88%", bottom:"85%", left:"2%"},
        {top:"5%", right:"25%", bottom:"85%", left:"65%"},
    ],
    outsideHitter1: [
        {top:"62%", right:"75%", bottom:"28%", left:"15%"},
        {top:"62%", right:"15%", bottom:"28%", left:"75%"},
        {top:"62%", right:"15%", bottom:"28%", left:"75%"},
        {top:"68%", right:"45%", bottom:"22%", left:"45%"},
        {top:"68%", right:"45%", bottom:"22%", left:"45%"},
        {top:"62%", right:"75%", bottom:"28%", left:"15%"},
    ],
    middleBlocker1: [
        {top:"28.5%", right:"85%", bottom:"61.5%", left:"5%"},
        {top:"5%", right:"80%", bottom:"85%", left:"10%"},
        {top:"28.5%", right:"2%", bottom:"61.5%", left:"88%"},
        {top:"12%", right:"104%", bottom:"78%", left:"-14%"},
        {top:"12%", right:"104%", bottom:"78%", left:"-14%"},
        {top:"12%", right:"104%", bottom:"78%", left:"-14%"},
    ],
    rightSideHitter: [
        {top:"84%", right:"50%", bottom:"6%", left:"40%"},
        {top:"28.5%", right:"88%", bottom:"61.5%", left:"2%"},
        {top:"2%", right:"10%", bottom:"88%", left:"80%"},
        {top:"28.5%", right:"2%", bottom:"61.5%", left:"88%"},
        {top:"75%", right:"2%", bottom:"15%", left:"88%"},
        {top:"82%", right:"22%", bottom:"8%", left:"68%"},
    ],
    outsideHitter2: [
        {top:"68%", right:"45%", bottom:"22%", left:"45%"},
        {top:"62%", right:"75%", bottom:"28%", left:"15%"},
        {top:"62%", right:"75%", bottom:"28%", left:"15%"},
        {top:"62%", right:"75%", bottom:"28%", left:"15%"},
        {top:"62%", right:"75%", bottom:"28%", left:"15%"},
        {top:"62%", right:"15%", bottom:"28%", left:"75%"},
    ],
    libero: [
        {top:"62%", right:"15%", bottom:"28%", left:"75%"},
        {top:"68%", right:"45%", bottom:"22%", left:"45%"},
        {top:"68%", right:"45%", bottom:"22%", left:"45%"},
        {top:"62%", right:"15%", bottom:"28%", left:"75%"},
        {top:"62%", right:"15%", bottom:"28%", left:"75%"},
        {top:"68%", right:"45%", bottom:"22%", left:"45%"},
    ],
    middleBlocker2: [
        {top:"12%", right:"104%", bottom:"78%", left:"-14%"},
        {top:"12%", right:"104%", bottom:"78%", left:"-14%"},
        {top:"12%", right:"104%", bottom:"78%", left:"-14%"},
        {top:"20%", right:"88%", bottom:"70", left:"2%"},
        {top:"28.5%", right:"82%", bottom:"61.5%", left:"8%"},
        {top:"28.5%", right:"2%", bottom:"61.5%", left:"88%"},
    ]
}

export const boundaries = {
    setter: [
        [
            {player: "outsideHitter1", border: "left"},
            {player: "libero", border: "bottom"}
        ],
        [
            {player: "outsideHitter1", border: "top"},
            {player: "libero", border: "left"}
        ],
        [
            {player: "outsideHitter1", border: "right"},
            {player: "libero", border: "left"},
            {player: "rightSideHitter", border: "top"},
        ],
        [
            {player: "outsideHitter1", border: "right"},
            {player: "middleBlocker2", border: "top"}
        ],
        [
            {player: "outsideHitter1", border: "bottom"},
            {player: "middleBlocker2", border: "right"}
        ],
        [
            {player: "outsideHitter1", border: "left"},
            {player: "middleBlocker2", border: "right"},
            {player: "rightSideHitter", border: "bottom"}
        ]
    ],
    outsideHitter1: [
        [
            {player: "middleBlocker1", border: "left"},
            {player: "setter", border: "right"},
            {player: "outsideHitter2", border: "bottom"}
        ],
        [
            {player: "middleBlocker1", border: "left"},
            {player: "setter", border: "bottom"},
        ],
        [
            {player: "middleBlocker1", border: "top"},
            {player: "setter", border: "left"}
        ],
        [
            {player: "libero", border: "right"},
            {player: "setter", border: "left"},
            {player: "outsideHitter2", border: "top"},
        ],
        [
            {player: "libero", border: "right"},
            {player: "setter", border: "top"}
        ],
        [
            {player: "libero", border: "bottom"},
            {player: "setter", border: "right"}
        ]
    ],
    middleBlocker1: [
        [
            {player: "outsideHitter1", border: "right"},
            {player: "rightSideHitter", border: "bottom"}
        ],
        [
            {player: "outsideHitter1", border: "right"},
            {player: "rightSideHitter", border: "left"},
            {player: "libero", border: "bottom"}
        ],
        [
            {player: "outsideHitter1", border: "bottom"},
            {player: "rightSideHitter", border: "left"}
        ],
        [],
        [],
        []
    ],
    rightSideHitter: [
        [
            {player: "outsideHitter2", border: "right"},
            {player: "middleBlocker1", border: "top"}
        ],
        [
            {player: "outsideHitter2", border: "bottom"},
            {player: "middleBlocker1", border: "right"}
        ],
        [
            {player: "outsideHitter2", border: "left"},
            {player: "middleBlocker1", border: "right"},
            {player: "setter", border: "bottom"}
        ],
        [
            {player: "outsideHitter2", border: "left"},
            {player: "libero", border: "bottom"}
        ],
        [
            {player: "outsideHitter2", border: "top"},
            {player: "libero", border: "left"}
        ],
        [
            {player: "outsideHitter2", border: "right"},
            {player: "libero", border: "left"},
            {player: "setter", border: "top"}
        ]
    ],
    outsideHitter2: [
        [
            {player: "rightSideHitter", border: "left"},
            {player: "libero", border: "right"},
            {player: "outsideHitter1", border: "top"}
        ],
        [
            {player: "rightSideHitter", border: "top"},
            {player: "libero", border: "right"}
        ],
        [
            {player: "rightSideHitter", border: "right"},
            {player: "libero", border: "bottom"}
        ],
        [
            {player: "rightSideHitter", border: "right"},
            {player: "middleBlocker2", border: "left"},
            {player: "outsideHitter1", border: "bottom"}
        ],
        [
            {player: "rightSideHitter", border: "bottom"},
            {player: "middleBlocker2", border: "left"}
        ],
        [
            {player: "rightSideHitter", border: "left"},
            {player: "middleBlocker2", border: "top"}
        ]
    ],
    libero: [
        [
            {player: "setter", border: "top"},
            {player: "outsideHitter2", border: "left"}
        ],
        [
            {player: "setter", border: "right"},
            {player: "outsideHitter2", border: "left"},
            {player: "middleBlocker1", border: "top"}
        ],
        [
            {player: "setter", border: "right"},
            {player: "outsideHitter2", border: "top"}
        ],
        [
            {player: "rightSideHitter", border: "top"},
            {player: "outsideHitter1", border: "left"}
        ],
        [
            {player: "rightSideHitter", border: "right"},
            {player: "outsideHitter1", border: "left"},
            {player: "middleBlocker2", border: "top"}
        ],
        [
            {player: "rightSideHitter", border: "right"},
            {player: "outsideHitter1", border: "top"}
        ]
    ],
    middleBlocker2: [
        [],
        [],
        [],
        [
            {player: "setter", border: "bottom"},
            {player: "outsideHitter2", border: "right"}
        ],
        [
            {player: "setter", border: "left"},
            {player: "outsideHitter2", border: "right"},
            {player: "libero", border: "bottom"}
        ],
        [
            {player: "setter", border: "left"},
            {player: "outsideHitter2", border: "bottom"}
        ]
    ]
}